import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import router from './router';
import VueI18n from 'vue-i18n';
import { locale } from 'core-js';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueI18n);

const messages = {
  tr: require('./locales/tr.json'),
  en: require('./locales/en.json'),
};

const i18n = new VueI18n({
  locale: 'tr',
  fallbackLocale: 'en',
  messages,
});

// Google Analytics 4 (GA4) gtag.js yükleme
(function() {
  var script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-L948MGTL0C';
  document.head.appendChild(script);
})();

// Google Analytics 4 (GA4) izleyici başlatma
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-L948MGTL0C'); // Burada izleyici ID'sini kendi ID'nizle değiştirmelisiniz

// Vue Router ile sayfa değişikliklerini GTM'ye bildirme
router.afterEach((to) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    pagePath: to.fullPath,
    pageTitle: document.title,
  });
});

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
